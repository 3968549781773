/* My config/settings file 
   see: https://stackoverflow.com/questions/47597384/canonical-way-to-define-common-constants-in-vue-js
*/



export default Object.freeze({
    SERVICES_BASE_URL: "https://stage.admin.findlegalhelp.clcnsw.org.au/api/services/",
    QUESTIONS_BASE_URL: "https://stage.admin.findlegalhelp.clcnsw.org.au/api/questions/",
    POSTCODES_BASE_URL: "https://stage.admin.findlegalhelp.clcnsw.org.au/api/localities/all",
    //SERVICES_BASE_URL: "http://103.4.235.69:8000/api/services/", //These work... cause it doesn't care about calls from oher servers.
	//SERVICES_BASE_URL: "http://localhost:8000/api/services/",
    //QUESTIONS_BASE_URL: "http://localhost:8000/api/questions/",
    //POSTCODES_BASE_URL: "http://localhost:8000/api/localities/all",
    //QUESTIONS_BASE_URL: "http://103.4.235.69:8000/api/questions/",

  })

